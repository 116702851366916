
.container[data-v-18379aca] {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding:0;
}
[data-v-18379aca] .toggle-btn{
	display: none;
}
[data-v-18379aca] .tools{
	padding-top: 0 !important
}
.title[data-v-18379aca] {
	height: 75px;
	line-height: 75px;
	margin-bottom: 0;
}
