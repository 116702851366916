.input-container[data-v-f0bbc239] {
  display: inline-flex;
}
.input[data-v-f0bbc239] {
  margin-bottom: var(--f0bbc239-margin);
  min-width: 150px;
  flex: 1;
}
.projectLink .el-select-dropdown__item[data-v-f0bbc239] {
  height: 59px !important;
}
.projectLink .el-select-dropdown__item > p[data-v-f0bbc239] {
  display: flex;
  flex-direction: column;
}
.projectLink .el-select-dropdown__item > p > span[data-v-f0bbc239] {
  font-size: 12px;
  line-height: 1em;
}
